import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Button from "../../components/Button"
import StaffCards from "../../components/Cards/StaffList"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import aboutHero from "./../../assets/images/fp-about-cyd.jpg"
import aboutFSRI from "./../../assets/images/fp-about-fsri.jpg"
import cydLogo from "./../../assets/images/fp-about-cyd-logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./../../styles/dark-mode.scss"
import { faExternalLink } from "@fortawesome/free-solid-svg-icons"

export default ({
  data: {
    pageInfo: {
      aboutContent,
      aboutContentP2,
      aboutCta,
      aboutSubtitle,
      aboutTitle,
      mainTitle,
      mainContent,
      mainCta,
      advisorysectionTitle,
      advisorysectionText,
    },
    staff: { nodes: staff },
  },
}) => {
  return (
    <Layout fluid={true} pageInfo={{ pageName: "First Responders About" }}>
      <SEO
        title="First Responders About"
        keywords={[`fsri`, `About`, `first responders`]}
      />
      <Container className="top-section" fluid>
        <Container>
          <Row>
            <div className="col-md-12 col-lg-6">
              <div>
                <Row className="mt-0">
                  <Col md={9}>
                    <h1>{mainTitle}</h1>
                  </Col>
                </Row>
                <p className="pb-3 pb-md-5">{mainContent}</p>
                <Button
                  icon={`arrow-right`}
                  className={`btn btn-bg-red`}
                  label={
                    <>
                      {mainCta}
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        className={"fa-external-link pl-2 mr-0"}
                      />
                    </>
                  }
                  target={"_blank"}
                  rel={"noopener norefferer"}
                  href={`https://FSRI.org/research-projects/close-your-door.html`}
                />
              </div>
            </div>
            <div
              className="col-md-12 col-lg-6 order-lg-last order-md-first pb-md-5 top-image"
              data-sal="slide-left"
              data-sal-duration="800"
              data-sal-easing="ease"
            >
              <img
                alt={"firefighters"}
                className="rounded img-fluid"
                width="100%"
                height="auto"
                src={aboutHero}
              />
            </div>
          </Row>
        </Container>
      </Container>
      <Container className="about-section pb-5" fluid>
        <Container>
          <Row>
            <Col md={7} className="">
              <h1 className="pb-2">{aboutTitle}</h1>
              <h2 className="pb-5">{aboutSubtitle}</h2>
              <p>{aboutContent}</p>
              <p className="pb-5">{aboutContentP2}</p>
              <Button
                icon={`arrow-right`}
                className={`btn btn-bg-red`}
                label={
                  <>
                    {aboutCta}
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      className={"fa-external-link pl-2 mr-0"}
                    />
                  </>
                }
                target={"_blank"}
                rel={"noopener norefferer"}
                href={`https://FSRI.org`}
              />
            </Col>
            <Col md={5}>
              <div className="float-right">
                <img
                  alt={"firefighters fighting house fire"}
                  className="rounded d-none d-lg-block"
                  width="350px"
                  height="486px"
                  src={aboutFSRI}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="staff-section-description" fluid>
        <Container className="staff-section-description">
          <h1>{advisorysectionTitle}</h1>
          <p className="pb-5 pt-5 mb-0">{advisorysectionText}</p>
        </Container>
      </Container>
      <Container className="staff-section" fluid>
        <StaffCards items={staff} />
      </Container>
      <Container className="logo-section text-center my-5">
        <div>
          <img
            alt={"logo"}
            className="float-center"
            width="254px"
            height="113px"
            src={cydLogo}
          />
        </div>
      </Container>
    </Layout>
  )
}

export const AboutDetailsQuery = graphql`
  query FPAboutQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-about" }) {
      aboutContent
      aboutContentP2
      aboutCta
      aboutSubtitle
      aboutTitle
      slug
      mainTitle
      mainContent
      mainCta
      featuredImage
      featuredImageAlt
      advisorysectionTitle
      advisorysectionText
    }
    staff: allNodeStaff(sort: { order: ASC, fields: [field_order] }) {
      nodes {
        id
        name: title
        title: field_staff_title
        description: field_description
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
